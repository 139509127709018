// lib/hooks/useInitialLanguage.ts
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import i18n from '../i18n';

export function useInitialLanguage() {
  const router = useRouter();

  useEffect(() => {
    const { lang } = router.query;
    const storedLang = localStorage.getItem('appLanguage');

    if (typeof lang === 'string' && ['en', 'de', 'fr', 'it'].includes(lang)) {
      i18n.changeLanguage(lang);
      localStorage.setItem('appLanguage', lang);
    } else if (storedLang) {
      i18n.changeLanguage(storedLang);
    }
  }, [router.query]);
}

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deCommon from '../public/locales/de/common.json';
import deData from '../public/locales/de/data.json';
import frCommon from '../public/locales/fr/common.json';
import frData from '../public/locales/fr/data.json';
import itCommon from '../public/locales/it/common.json';
import itData from '../public/locales/it/data.json';

// Declare module augmentation for the resource type
declare module 'i18next' {
  interface CustomTypeOptions {
    resources: {
      common: typeof deCommon;
      data: typeof deData;
    };
    defaultNS: 'common';
    allowObjectInHtmlChildren: true;
  }
}

i18n.use(initReactI18next).init({
  resources: {
    de: { common: deCommon, data: deData },
    fr: { common: frCommon, data: frData },
    it: { common: itCommon, data: itData },
  },
  lng: 'de', // default language
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import { Dispatch, ReactNode, SetStateAction, createContext } from 'react';

interface AppProps {
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  left?: ReactNode;
  right?: boolean;
  setRight: Dispatch<SetStateAction<boolean>>;
  token: string;
}

export const AppContext = createContext<AppProps>({} as AppProps);

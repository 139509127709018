import styles from './Spinner.module.css';

const Spinner = () => {
  return (
    <div className="flex items-center justify-center w-full h-full my-12">
      <div data-testid="spinner" className={styles.ring}></div>
    </div>
  );
};

export default Spinner;

import { ExitValidationEnum } from '../models/exitValidationEnum';

export function exitValidationModule(exitValue: ExitValidationEnum) {
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();
  if (isBrowser) {
    if (window.exitValidationModule) {
      window.exitValidationModule(exitValue);
    }
  }
}
